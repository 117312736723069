<template>
  <div class="catalogue-page">
    <b-card class="top-title">
      <b-row class="title history-nav" align-v="baseline">
        <b-col sm="12" lg="6" class="mr-auto"><h1>Karma Catalogue</h1></b-col>
        <b-col sm="12" lg="6">
          <div class="ml-auto row">
            <v-select
              class="col-6"
              v-if="!isBusy"
              v-model="karma_program_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="karma_program_option"
              :reduce="(val) => val.hashid"
              @input="(val) => getCategoryWithCatalogue()"
              label="label"
              :clearable="false"
              :searchable="true"
              input-id="karma_program_id"
              name="karma_program_id"
              placeholder="Select Karma Program"
            />
            <b-button
              variant="primary"
              class="d-flex align-items-center"
              :to="{ name: 'karma-leaderboard' }"
              pill
            >
              <feather-icon icon="ArrowLeftIcon" class="mr-50" />
              <span class="font-weight-bold d-none d-md-flex align-middle"
                >Leaderboard</span
              >
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="faqData.length < 1" class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">Data Not Available 🕵🏻‍♀️</h2>
        <p class="mb-2">
          Oops! 😖 May data is empty or Try adjusting your filter.
        </p>
        <b-img
          fluid
          :src="require('@/assets/images/pages/Search-rafiki.png')"
          alt="Error page"
          width="500"
        />
      </div>
    </div>
    <b-container fluid v-if="faqData.length > 0">
      <b-row class="catalogue-content">
        <b-col sm="12" lg="5">
          <b-card>
            <b-list-group v-b-scrollspy:listgroup-ex>
              <b-list-group-item
                v-for="(category, index) in faqData"
                v-bind:key="index"
                class="category-card"
                :href="'#list-item-' + category.hashid"
                >{{ category.name }}</b-list-group-item
              >
            </b-list-group>
          </b-card>
        </b-col>
        <b-col sm="12" lg="7">
          <div
            id="listgroup-ex"
            style="position: relative; overflow-y: auto; height: 100vh"
          >
            <b-card
              v-for="(category, index) in faqData"
              v-bind:key="index"
              :header="category.name"
              :id="'list-item-' + category.hashid"
              header-bg-variant="primary"
              header-text-variant="white"
              class="font-weight-bold"
              style="font-size: 18px"
            >
              <b-row
                v-for="(karma_catalogue, index) in category.karma_catalogue"
                v-bind:key="index"
                class="justify-content-between mb-1 border-bottom mt-2"
              >
                <b-col md="9" class="tab-details">
                  <h5>{{ karma_catalogue.karma_name }}</h5>
                  <b-badge v-if="karma_catalogue.sub_karma_category_name" class="tab-cat" variant="primary">{{karma_catalogue.sub_karma_category_name}}</b-badge>
                  <span v-if="karma_catalogue.description" class="text-secondary small d-block my-1"
                    >{{karma_catalogue.description}}</span
                  >
                </b-col>
                <b-col md="3" class="tab-points text-right"
                  ><h4>
                    {{ karma_catalogue.karma_point }}
                    <span class="small font-weight-bold">Pts</span>
                  </h4>
                  <b-button
                    v-if="
                      $can('karma_request_create') &&
                      karma_catalogue.apply_permission.includes('employee') &&
                      category.karma_program_status == 'started'
                    "
                    variant="primary"
                    size="sm"
                    class="mb-1"
                    :to="{
                      name: 'add-karma-request',
                      params: {
                        id: karma_catalogue.hashid,
                        karma_program_id: karma_program_id,
                        karma_category_id: category.hashid,
                        sub_karma_category_id:
                          karma_catalogue.sub_karma_category_id,
                      },
                    }"
                  >
                    <span class="text-nowrap">Claim Now</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
  
  <script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import infiniteScroll from "vue-infinite-scroll";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
  BInputGroup,
  VBTooltip,
  BInputGroupAppend,
  BAvatar,
  BOverlay,
  BAlert,
  BNavbarNav,
  BNavItem,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import { required, email, numeric } from "@validations";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import store from "@/store";
import bomb from "@/libs/bomb/bomb";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";
import axios from "@axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
import karmaCatalogueStoreModule from "./karmaCatalogueStoreModule";
Vue.use(VueCookies);

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroup,
    BOverlay,
    BAlert,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    StarRating,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    BAvatar,
  },
  directives: {
    "b-tooltip": VBTooltip,
    infiniteScroll,
  },
  data() {
    return {
      tempFlag: true,
      lastPage: 10,
      nextPage: 1,
      currentPage: 1,
      userData: this.$cookies.get("userData"),
      all_notifications: false,
      allSelected: false,
      is_loading: {
        submit: false,
      },
      redirectEmailNotification: "",
      data_local: {
        title: "",
        get_customfields_data: [],
      },
      faqData: [],
      subscriptions: [],
      searchQuery: "",
      filterByType: "Starred",
      typeOption: [
        {
          code: "one",
          label: "One",
        },
        {
          code: "many",
          label: "Many",
        },
      ],
      leaderboard: [],
      isBusy: false,
      designationOptions: [],
      karma_program_option: [],
      filterByDesignationId: null,
      karma_program_id: this.$route.params.karma_program_id
        ? this.$route.params.karma_program_id
        : "",
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-karma-catalogue";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(
        USER_APP_STORE_MODULE_NAME,
        karmaCatalogueStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    this.getCategoryWithCatalogue();
    if (this.$route.query.search) {
      this.searchQuery = this.$route.query.search;
    }
    if (this.$route.query.filterByType) {
      this.filterByType = this.$route.query.filterByType;
    }
    // this.getLeaderboard();
  },
  mounted() {},
  watch: {
    searchQuery(val) {
      if (val.length > 3 || val.length == 0) {
        this.getLeaderboard();
      }
    },
  },
  methods: {
    loadMore: function () {
      if (
        (this.nextPage === 1 && this.tempFlag === true) ||
        (this.nextPage > 1 && this.tempFlag === false)
      ) {
        this.tempFlag = false;
        if (this.currentPage < this.lastPage) {
          this.isBusy = true;

          let params = {};
          params.page = this.nextPage;
          params.q = this.searchQuery;
          params.type = this.filterByType;
          params.filterByDesignationId = this.filterByDesignationId;

          this.$http
            .post("/leaderboard", params)
            .then((res) => {
              this.leaderboard = this.leaderboard.concat(res.data.data);
              this.nextPage += 1;
              this.lastPage = res.data.meta.last_page;
              this.currentPage = res.data.meta.current_page;
              this.isBusy = false;
            })
            .catch((error) => {
              console.log(error);
              this.isBusy = false;
            });
        }
      }
    },
    AddFilter(type) {
      this.filterByType = type;
    },
    getCategoryWithCatalogue() {
      const self = this;

      let data = {};
      data.url = "get-category-with-catalogue";
      data.params = {
        q: this.faqSearchQuery,
        karma_program_id: self.karma_program_id,
      };
      this.isBusy = true;

      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.faqData = res.data.data;
          self.karma_program_option = res.data.karma_program_option;
          this.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Categories Getting Failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
